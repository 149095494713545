
.name-ender{
    font-size: 100px;

}


.home-page{
    display: flex;
    justify-content: space-evenly;

    .box-zone{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        
    }

    .text-zone{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        // left: 5%;
        // top: 40%;
        // transform: translateY(-50%);
        // width: 50%;
        // max-height: 90%;

        animation: fadeIn 1s 1.8s backwards
    }
    h1{
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;

        &::before{

            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            opacity: .6;
        }
        &::after{

            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            opacity: .6;
            position: absolute;
            margin-top: -10px;
            margin-left: 30px;



        }

        img{
            width: 32px;
        }
    }
    h2{
        padding: 20px;
        border-top: 20px;
        color: #8d8d8d;
        font-size: 26px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;

        &::before{

            content: "<h2>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: relative;
            opacity: .6;
        }
        &::after{

            content: "</h2>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            opacity: .6;
            position: relative;


        }
    }

    .flat-button{
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        animation: fadeIn 1s 1.8s backwards;
    }



}

@media only screen and (max-width: 1048px) {
    
    
    .home-page {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;



      .box-zone {
        margin-left: -200px;

          
        flex-direction: row;

        width: 100%;
        justify-content: center;
        align-items: center;

        
    }
    .flat-button{
        font-size: 11px;
        margin-right: 3px;
        margin-left: 3px;
        overflow-x: auto;

    }
    }

    
    
    
  }


  @media only screen and (max-width: 700px) {
    
    
    .home-page {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-top: 10%;




      .box-zone {
        margin-left: -200px;
        margin-top: 20%;

          
        flex-direction: row;

        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 40%;
        
    }
    .flat-button{
        font-size: 11px;
        margin-right: 3px;
        margin-left: 3px;
        overflow-x: auto;

    }
    
    }

    
    
    
  }


  @media only screen and (max-width: 400px) {
    
    
    .home-page {




      .box-zone {
        margin-left: -200px;
        margin-top: 40%;

        margin-bottom: 75%;


        
    }

    
    }

    
    
    
  }