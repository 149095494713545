.work-page{
    h1{
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding: 20px;
        border-top: 20px;
        color: white;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
    
        &::before{
    
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: relative;
            opacity: .6;
            margin: 5px;
        }
        &::after{
    
            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            opacity: .6;
            position: relative;
            margin: 5px;
    
    
        }
    }
    h3 {
        padding-top: 0.25em;
      }
      
      
      .vertical-timeline-element-content {
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
          0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
        padding: 2em 3em !important;
      
      
      }
      .vertical-timeline-element-date{
          color: rgb(201, 251, 255);
          font-size: 2rem !important;
          margin-top: -8px;
      
      }
      .vertical-timeline-element-subtitle {
          margin: 0;
          font-size: 1.3rem;
      
      }
      
      .vertical-timeline-element-title {
          margin: 0;
          font-size: 1.6rem;
      }
      .vertical-timeline-element-job-title {
          margin: 0;
          font-size: 2rem;
          font-weight: bold;
          font-style: italic;
      }
      #description {
        margin: 1.5em 0 2em 0;
        font-size: 1.5rem;
      }
      
      .button {
        text-decoration: none;
        padding: 0.5em 1em;
        border-radius: 5px;
        color: white;
      }
      
      .workButton {
        background-color: #06d6a0;
      }
      
      .workButton:hover {
        background-color: #0ac593;
      }
      
      .schoolButton {
        background-color: #f9c74f;
      }
      
      .schoolButton:hover {
        background-color: #f3bc3c;
      }
      
      @media only screen and (max-width: 1170px) {
        .vertical-timeline-element-date {
          display: block !important;
          float: none !important;
          color: black;
          margin-top: 1.5em;
          font-size: 1.3rem !important;
        }
      }
}

