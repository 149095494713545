.App {
  display: flex;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
  margin-left: 3%;
  // position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.top-tags {
  bottom: auto;
  top: 35px;
  margin-left: 15px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  // position: absolute;
  bottom: 0;
  left: 140px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
  margin-left: 15px;
}

.bottom-tag-html {
  margin-left: -10px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566 px;
  // position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  -webkit-animation: fadeIn 1s forwards;
  -moz-animation: fadeIn 1s forwards;
  -o-animation: fadeIn 1s forwards;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  text-align: center;
}

.stage-cube-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cubespinner {
  position: relative;
  display: flex;
  margin-top: -120px;
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 11s;
  -moz-animation-name: spincube;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 11s;
  -webkit-animation-name: spincube;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 11s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }


  @keyframes spincube {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1) rotateX(0deg), rotateY(0deg), rotateZ(0deg);
    }
    16% {
      transform: scale(0.9) rotateY(-90deg);
    }
    33% {
      transform: scale(1) rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: scale(0.95) rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: scale(1.1) rotateY(-270deg) rotateZ(90deg);
    }
    83% {
      transform: scale(1) rotateX(90deg);
    }
  }

  .face1 {
    transform: rotate(0.1deg) rotateY(0deg) rotateZ(0deg);
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }
  .face3 {
    transform: rotateY(270deg) translateZ(100px);
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }
  .face5 {
    transform: rotateX(90deg) rotateZ(-90deg) translateZ(100px);
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
  }
}



.about-page {
  .text-zone {
    position: relative;
    left: 10%;
    top: 20%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
  }

  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
  }
}

@media only screen and (max-width: 400px) {

    .bottom-tags{
        margin-top: 10%;
    }
    .top-tags{
        margin-top: 10%;
    }
 
 

    @keyframes spincube {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1) rotateX(0deg), rotateY(0deg), rotateZ(0deg);
        }
        16% {
          transform: scale(1) rotateY(-90deg);
        }
        33% {
          transform: scale(1) rotateY(-90deg) rotateZ(90deg);
        }
        50% {
          transform: scale(1) rotateY(-180deg) rotateZ(90deg);
        }
        66% {
          transform: scale(1) rotateY(-270deg) rotateZ(90deg);
        }
        83% {
          transform: scale(1) rotateX(90deg);
        }
      }
  }
