
.nav-bar{
    background: #181818;
    width: 60px;
    height: 100%;
    z-index: 3;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    

    .logo{
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    img{
        margin: 3px auto;
        width: 24px;
        height: auto;
    }

    &.sub-logo {
        width: 50px;
    }

    nav {
        text-align: center;

        
    }

    a{
        font-size: 22px;
        color: #4d4d4e;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 51px;
        height: 51px;
        position: relative;

        i{
            transition: all .3s ease-out;
        }

        &:hover{
            color: #ffd700;
            svg {
                opacity: 0;
            }

            &:after{
                opacity: 1;
            }
        }

        &:after{
            font-size:9px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            opacity: 0;
            transition: all .3s ease-out;


         
        }
    
 
        

    }
    a.about-link {
        &:after {
            content: "ABOUT";
        }
    }
    a.experience-link {
        &:after {
            content: "WORK";
        }
    }
    a.home-link {
        &:after {
            content: "HOME";
        }
    }
    a.contact-link {
        &:after {
            content: "CONTACT";
        }
    }
    a.linkedin-link {
        &:after {
            content: "LINKEDIN";
        }
    }
    a.github-link {
        &:after {
            content: "GITHUB";
        }
    }

    a.active{
        svg{
            color: #ffd700;
        }
    }

    ul {
        text-align: center;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;

   
    }

}